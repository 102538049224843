import { ComponentConfig } from '@measured/puck';

import { EventBanner } from './event-banner';
import trpc from '@/app/_trpc/vanillaClient';

export type EventBannerBlockProps = {
  event: {
    label: string;
    value: string;
  };
  category?: {
    id: string;
    name: string;
    slug: string;
  };
};

export const EventBannerBlock = (accountSlug: string): ComponentConfig<EventBannerBlockProps> => {
  return {
    fields: {
      event: {
        type: 'select',
        label: 'Select an event to display',
        options: [{ label: 'Featured event', value: 'featured' }],
      },
      category: {
        type: 'external',
        placeholder: 'Select category',
        fetchList: async () => {
          const categories = await trpc.library.getCategories.query({ accountSlug });
          return categories.map((category) => ({
            name: category.name,
            slug: category.slug,
            id: category.id,
          }));
        },
        label: 'Category to get event from (optional)',
        getItemSummary: (item) => item?.name || '',
      },
    },
    render: ({ category }) => <EventBanner accountSlug={accountSlug} categoryId={category?.id} />,
  };
};
