'use client';

import { useHandleLibraryEventSocketMessages } from '@/app/_hooks/socket-messages-handler';

export default function SocketProvider({
  eventData,
  socketServiceUrl,
}: {
  eventData: any;
  socketServiceUrl: string;
}) {
  // Handle event page socket messages
  useHandleLibraryEventSocketMessages({ eventData, socketServiceUrl });

  return <></>;
}
