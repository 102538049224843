import { useSocketService, useSocketServiceEvent } from '@inderes/videosync-react-components';
import { ClientToServerMessageType } from '@inderes/videosync-types';
import { useCallback, useEffect } from 'react';

type SocketHandlerProps = {
  eventData?: any; // Todo: eventId should probably be enough if we get trpc refetch here
  socketServiceUrl: string;
};

// TODO: make this more elegant, not just refresh the page
export const useHandleLibraryEventSocketMessages = ({ eventData, socketServiceUrl }: SocketHandlerProps) => {
  const { socket, connected } = useSocketService({ url: socketServiceUrl });

  useEffect(() => {
    if (connected && eventData?.id) {
      socket.emit(ClientToServerMessageType.receiveEventUpdates, eventData.id);
      socket.emit(ClientToServerMessageType.receiveEventStateUpdates, eventData.id);
      socket.emit(ClientToServerMessageType.playerStart, {
        eventId: eventData.id,
      });
    }
  }, [connected, eventData, socket]);

  useSocketServiceEvent(
    socket,
    'eventUpdated',
    useCallback(
      (updatedEventData) => {
        console.log('updatedEventData', updatedEventData);
        if (eventData.state !== updatedEventData?.state) {
          window.location.reload();
        }
      },
      [eventData.state],
    ),
  );

  useSocketServiceEvent(
    socket,
    'eventStateUpdated',
    useCallback((message: { state: number }) => {
      console.log('eventStateUpdated', message);
      window.location.reload();
    }, []),
  );
};
