import { ComponentConfig } from '@measured/puck';

import { EventContent } from './event-content';
import { GetEventData } from '@/app/(event-site)/data';
import { CategoryEvents } from '../category-events/category-events';
import { useI18n } from '@/app/(library-site)/locales/client';

export type EventContentBlockProps = {
  showRelatedEvents: boolean;
};

export const EventContentBlock = (
  accountSlug: string,
  eventData: GetEventData & { category?: { id: string; name: string; slug: string } },
): ComponentConfig<EventContentBlockProps> => {
  const t = useI18n();
  return {
    fields: {
      showRelatedEvents: {
        type: 'radio',
        label: 'Show related events',
        options: [
          { label: 'On', value: true },
          { label: 'Off', value: false },
        ],
      },
    },
    defaultProps: {
      showRelatedEvents: true,
    },
    render: ({ showRelatedEvents }) => (
      <>
        <EventContent accountSlug={accountSlug} eventData={eventData} />
        {showRelatedEvents && eventData?.category && (
          <CategoryEvents
            title={t('related.videos')}
            showAllEventsLink
            accountSlug={accountSlug}
            category={eventData.category}
            numberOfEvents={8}
          />
        )}
      </>
    ),
  };
};
