'use client';

import { useChangeLocale } from "@/app/(library-site)/locales/client";
import { useEffect } from "react";

interface Props {
  toLocale: string;
}

/**
 * This component is a little hack to change the locale on the client side
 * This is currently used to change the locale when it was not supported in domains config
 * eg. when user has "en" but it's not supported, it will send to "fi" instead
 */
export default function LocaleChange({ toLocale }: Props) {
  const changeLocale = useChangeLocale();

  useEffect(() => {
    if (toLocale !== 'en' && toLocale !== 'fi' && toLocale !== 'sv') {
      return;
    }
    return changeLocale(toLocale);
  }, [changeLocale, toLocale]);
  return null;
}