'use client';

import type { Data } from '@measured/puck';
import { Render } from '@measured/puck';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { getConfigWithData } from '@/app/_puck/library-puck.config';
import { GetEventData } from '@/app/(event-site)/data';
import SocketProvider from '@/app/(library-site)/socket-provider';
import { playerDataAtom } from '@/app/(library-site)/library-state';

export function Client({
  data,
  accountSlug,
  eventData,
  playerData,
  socketServiceUrl,
}: {
  data: Data;
  accountSlug: string;
  eventData?: GetEventData;
  playerData: any;
  socketServiceUrl: string;
}) {
  const config = getConfigWithData(accountSlug, eventData);
  const setPlayerData = useSetAtom(playerDataAtom);

  useEffect(() => {
    if (playerData) {
      setPlayerData(playerData);
    }
  }, [setPlayerData, playerData]);

  return (
    <>
      {eventData?.id && (
        <SocketProvider socketServiceUrl={socketServiceUrl} eventData={eventData} />
      )}
      <Render config={config} data={data} />
    </>
  );
}
